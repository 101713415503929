import React, { useEffect, useState } from "react";
import { Container, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { Link } from "react-router-dom";
import useStyles from "./style/style";
import NavBar from "../../components/NavBar";
import SimpleBackdrop from "../../components/BackDrop";
import { appInsights } from '../../config/appInsight';

appInsights.trackPageView();

function Home() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const setUploadStatus = useState(false);
  const [noContentText, SetContentText] = useState("");
  const classes = useStyles();
  var imageExtensions = ["jpg", "png", "jpeg", "gif"]

  const api = (param = "") => {
    fetch(`https://memekultureapi.azurewebsites.net/api/Media${param}`)
      .then((response) => response.json())
      .then((data) => {
        SetContentText("");
        if (data?.status === 404) {
          SetContentText("No contents found for search");
          setData([]);
          return setLoading(false);
        }
        SetContentText("");
        setData([]);
        setData(data);
        return setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const uploadApi = (formData) => {
    if (formData === null) {
      return;
    }
    const requestOptions = {
      method: 'POST',
      body: formData
    };
    fetch('https://memekultureapi.azurewebsites.net/api/Media/upload', requestOptions)
      .then(response => response.json())
      .then((data) => {
        if (data?.status > 400) {
          alert('Upload failed :(');
          return setUploadStatus(false);
        }
        alert('Upload successful! Please refresh browser');
        return setUploadStatus(true);
      })
      .catch((error) => {
        console.log(error)
      });
  };

  // useEffect(() => {
  //   api();
  // }, [uploadStatus]);

  useEffect(() => { }, []);

  return (
    <>
      {loading ? <SimpleBackdrop /> : ""}
      <Container maxWidth="md" style={{ minHeight: "92vh" }}>
        <NavBar api={api} uploadApi={uploadApi} />
        <Typography variant="h5" className={classes.mainTypo}>
          <TrendingUpIcon className={classes.typoIcon} />
          Trending
        </Typography>
        <Grid container spacing={3} className={classes.grid}>
          <>
            {data.map((v, k) => (
              <Grid item lg={4} md={4} sm={6} xs={12} key={k}>
                {imageExtensions.includes(v.extension) ? (
                  <Link to={`/media/${v.id}`}>
                    <div className={classes.imgMain}>
                      <img
                        className={classes.img}
                        src={v.blobEndpoint}
                        alt=""
                      />
                    </div>
                  </Link>
                ) : (
                  <Link to={`/media/${v.id}`}>
                    <div className={classes.videoMain}>
                      <video className={classes.video} autoPlay loop muted playsInline>
                        <source src={v.blobEndpoint} type="video/mp4" />
                      </video>
                    </div>
                  </Link>
                )}
              </Grid>
            ))}
          </>

          <Typography
            color="secondary"
            variant="h4"
            className={classes.noContent}
          >
            {noContentText}
          </Typography>
        </Grid>
      </Container>
    </>
  );
}
export default Home;
