import React, { useState, useEffect } from "react";
import { TextField, Typography, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import useStyles from "./style/style";
import { useHistory, useLocation } from "react-router";
import Upload from "../../components/Upload";

function NavBar({ api, uploadApi }) {
  const classes = useStyles();
  const [state, setState] = useState("");
  const [serchState, setSearchState] = useState("");
  const history = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    if (state) {
      const trimmed = state.replace(/[.'/#!$%^&*;:{}=\-_`~()]/g, "").replace(/ /g, "");
      const tags = trimmed.split(",");
      history.push({
        pathname: "/",
        search: tags
          ?.map((val, k) => {
            return `tags=${val}${k === tags?.length - 1 ? "" : "&"}`;
          })
          .join("")
          .toString(),
      });
    } else if (serchState === "") {
      history.push({
        pathname: "/",
      });
    } else {
      history.push({
        pathname: "/",
      });
    }
    // eslint-disable-next-line
  }, [state]);
  useEffect(() => {
    if (serchState === "") {
      history.push({
        pathname: "/",
      });
    }
    // eslint-disable-next-line
  }, [serchState]);
  useEffect(() => {
    api(search);
    // eslint-disable-next-line
  }, [search]);

  return (
    <>
      <Typography align="center" variant="h1" className={classes.text}>
        MEMEKULTURE
      </Typography>
      <Typography align="center" color="textSecondary" variant="subtitle2" className={classes.subtext}>
        <i>A place to get the hottest memes, gifs and clips!</i>
      </Typography>
      <div style={{display:'flex', flexDirection: 'row'}}>
        <TextField
          className="inputRounded"
          placeholder="e.g. drake, kobe"
          color="secondary"
          variant="outlined"
          fullWidth
          onChange={(e) => setSearchState(e?.target?.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setSearchState(e?.target?.value);
              setState(serchState);
            }
          }
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  color="action"
                  className={classes.icon}
                  onClick={() => {
                    setState(serchState);
                  }}
                />{" "}
              </InputAdornment>
            ),
          }}
        />
        <Upload uploadApi={uploadApi} />
      </div>
    </>
  );
}
export default NavBar;
