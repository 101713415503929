import { Grid, Container, Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Link } from "react-router-dom";
import useStyles from "./style/style";
import SimpleBackdrop from "../../components/BackDrop";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { appInsights } from '../../config/appInsight';

appInsights.trackPageView();

function MediaPage() {
  const [data, setData] = useState([]);
  const [blobUrl, setBlobUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  var imageExtensions = ["jpg", "png", "jpeg", "gif"]

  const match = useRouteMatch();
  useEffect(() => {
    fetch(
      `https://memekultureapi.azurewebsites.net/api/Media/${match.params.id}`
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [match.params.id]);
  useEffect(() => {
    if (!loading) {
      fetch(
        data.blobEndpoint
      )
        .then((response) => response.blob())
        .then((blob) => {
          setBlobUrl(URL.createObjectURL(blob));
        })
        .catch((error) => console.log(error));
    }
  }, [loading, data.blobEndpoint]);

  return (
    <>
      <Container maxWidth="md">
        <Link to="/" className={classes.link}>
          <Typography variant="h6" className={classes.mainTypo}>
            <KeyboardBackspaceIcon className={classes.typoIcon} />
            Home
          </Typography>
        </Link>
        {loading ? <SimpleBackdrop open={true} /> : ""}
        <Grid
          container
          spacing={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "85vh" }}
        >
          <Grid item lg={8} md={8} sm={12} xs={12}>
            {imageExtensions.includes(data.extension) ? (
              <div className={classes.imgMain}>
                <img className={classes.img} src={data?.blobEndpoint} alt="" />
                <div className={classes.badgeMain}>
                  {data?.tags?.map((v, k) => (
                    <div key={k} className={classes.badge}>
                      #{v}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <div className={classes.videoMain}>
                  <video
                    className={classes.video}
                    key={data.id}
                    autoPlay
                    controls
                    playsInline
                  >
                    <source src={data.blobEndpoint} />
                  </video>
                  <div className={classes.badgeMain}>
                    {data?.tags?.map((v, k) => (
                      <div key={k} className={classes.badge}>
                        #{v}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} className={classes.grid}>
            <a key= {data.id} href={blobUrl ?? data.blobEndpoint} download={data.id + "." + data.extension} className={classes.anchor}>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                fullWidth
                className={classes.button}
              >
                Download
              </Button>
            </a>
            <div className={classes.view}>
              <VisibilityIcon style={{ paddingRight: "10px" }} />
              {data.numberOfRetrieves} views
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
export default MediaPage;
