import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: "white",
  },

  mainTypo: {
    display: "flex",
    alignItems: "center",
    paddingTop: "25px",
    paddingBottom: "25px",
  },
  typoIcon: {
    fontSize: "32px",
    paddingRight: "10px",
  },
  imgMain: {
    width: "100%",
    height: "60vh",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  badge: {
    margin: "2px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "2px",
    paddingBottom: "2px",
    borderRadius: "15px",
    background: "#2E2E2E",
  },
  //comment
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "25px",
  },
  anchor: {
    textDecoration: "none",
    width: "100%",
  },

  videoMain: {
    width: "100%",
    height: "60vh",
  },
  video: {
    height: "100%",
    width: "100%",
    objectFit: "fill",
  },
  badgeMain: {
    display: "flex",
    marginTop: "10px",
  },
  button: {
    padding: "13px",
    fontSize: "20px",
  },
  view: {
    display: "flex",
    alignItems: "center",
    paddingTop: "15px",
    fontSize: "20px",
  },
}));
export default useStyles;
