import { Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./style/style";
function Footer() {
  const classes = useStyles();

  return (
    <>
      <hr className={classes.line} />
      <div className={classes.main}>
        <a
          href="https://twitter.com/messages/compose?recipient_id=1410036461806571524"
          target="_blank"
          rel="noreferrer"
          className={classes.anchor}
        >
          <Typography>Contact Us</Typography>
        </a>
        <Typography className={classes.typo} variant='h1'>|</Typography>
        <Typography className={classes.typo}>
          Copyright <span style={{ fontSize: "18px" }}> © </span> FR$ {new Date().getFullYear()}
        </Typography>
      </div>
    </>
  );
}
export default Footer;
