import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "4vh",
  },
  anchor: {
    fontSize: "16px",
    paddingLeft: "25px",
    color: "white",
    textDecoration: "none",
  },
  typo: {
    fontSize: "16px",
    paddingLeft: "25px",
  },
  line: {
    border: "1px solid #202020",
  },
}));
export default useStyles;
