import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  mainTypo: {
    display: "flex",
    marginTop: "38px",
    marginBottom: "-28px",
  },
  typoIcon: {
    color: "#00FF99",
    width: "50px",
    height: "38px",
  },
  grid: {
    marginTop: "30px",
  },
  imgMain: {
    width: "100%",
    height: "35vh",
    [theme.breakpoints.down("sm")]: {
      height: "50vh",
    },
  },
  img: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  videoMain: {
    width: "100%",
    height: "35vh",
    [theme.breakpoints.down("sm")]: {
      height: "50vh",
    },
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    borderRadius: "8px",
  },
  noContent: {
    fontSize: "25px",
    paddingTop: "50px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));
export default useStyles;
