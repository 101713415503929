import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from '@material-ui/core/Button';
import { Modal, Button as ReactButton } from "react-bootstrap";
import Form from 'react-bootstrap/Form'
import { makeStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function Upload({ uploadApi }) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [formElements, setFormElements] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (formElements !== null && fileObject !== null) {
      const formData = new FormData()
      formData.append('tags', formElements.tags.value)
      formData.append('extension', fileObject.extension)
      formData.append('file', fileObject.file)
      uploadApi(formData);

    }
    // eslint-disable-next-line
  }, [formElements]);

  return (
    <>
      <Button
        color="secondary"
        className={classes.button}
        size="large"
        startIcon={<CloudUploadIcon />}
        onClick={handleShow}
      />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Meme data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form controlid="formData" onSubmit={(e) => {
            e.preventDefault();
            handleClose();
            setFormElements(e?.target?.elements);
          }}>
            <Form.Group controlId="tags" className="mb-3">
              <Form.Label>Tags</Form.Label>
              <Form.Control required />
              <Form.Text className="text-muted">
                Give tags that describe the content e.g. echoke, drake
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="file" className="mb-3">
              <Form.Control required type="file" onChange={(e) => {
                setFileObject({
                  'file': e.target.files[0],
                  'extension': e.target.files[0]['name'].split('.')[1]
                });
              }} />
            </Form.Group>
            <ReactButton variant="primary" type="submit">
              Submit
            </ReactButton>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Upload;