import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  text: {
    paddingTop: "25px",
    paddingBottom: "10px",
    fontSize: "35px",
    fontWeight: "700",
  },
  icon: {
    borderRadius: "25px",
    cursor: "pointer",
  },
  subtext: {
    paddingBottom: "10px",
  }
}));
export default useStyles;
