import React from "react";
import {
  unstable_createMuiStrictModeTheme as createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import Routes from "./utils/router";
import { Paper } from "@material-ui/core";
const theme = createTheme({
  palette: {
    type: "dark",
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Paper
        style={{ minHeight: "100vh", borderRadius: "0px", background: "black" }}
      >
        <div className="App">
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </div>
      </Paper>
    </ThemeProvider>
  );
}

export default App;
