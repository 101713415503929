import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import MediaPage from "../pages/MediaPage";
import Footer from "../components/Footer";

function Routes() {
  return (
    <>
      <BrowserRouter>
        <Route exact path="/" component={Home} />
        <Switch>
          <Route exact path="/media/:id" component={MediaPage} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </>
  );
}
export default Routes;
